import React from 'react';
import './App.css';
import { Container, Typography, Box } from '@mui/material';
import HookEchoDesc from "./HookEchoDesc";
import NavBar from "./NavBar";
import Grid from '@mui/material/Grid';

function HookEcho() {
    return (
    <>
        <Grid item xs={12}>
            <Typography variant="h4" component="h1" gutterBottom>
                Hook Echo
            </Typography>
        </Grid>

        <HookEchoDesc/>
    </>
    );
}

export default HookEcho;