import Grid from '@mui/material/Grid';

function TheStartlingDesc() {
  return (
      <>
      <Grid item xs={12}>
        <img
          alt="The Startling"
          src="images/startling_screenshot.png"
          style={{
            width: "100%",  // Ensure the image spans the width of its container
            height: "auto",  // Maintain aspect ratio
            maxWidth: "70vw" // Ensure it doesn't exceed viewport width (optional)
          }}
        />
      </Grid>

      <Grid item xs={12} className={"desc-padding"}>
          We are excited to announce The Startling. Are you brave enough to set foot in The Startling woods?
          Prepare to experience classic horror in an interactive story format,
          where your choices will help determine the outcome.
      </Grid>
      </>
  );
}

export default TheStartlingDesc;