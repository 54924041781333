import Grid from '@mui/material/Grid';

function HookEchoDesc() {
   return (
       <>
         <Grid item xs={12}>
           <img
             alt="The Startling"
             src="images/supercell.jpg"
             style={{
               width: "100%",  // Ensure the image spans the width of its container
               height: "auto",  // Maintain aspect ratio
               maxWidth: "70vw" // Ensure it doesn't exceed viewport width (optional)
             }}
           />
         </Grid>

         <Grid item xs={12} sx={{ 'margin-bottom': '20' }}>
             Take control of a storm chasing vehicle and chase twisters in the great plains. Set to release in March 2026.
         </Grid>
       </>
   );
}

export default HookEchoDesc;