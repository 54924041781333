import React from 'react';
import './App.css';
import { Container, Typography, Box, Link } from '@mui/material';
import TheStartlingDesc from "./TheStartlingDesc";
import NavBar from "./NavBar";
import Grid from '@mui/material/Grid';

function TheStartling() {
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h4" component="h1" gutterBottom>
                    The Startling
                </Typography>
            </Grid>

            <TheStartlingDesc/>

            <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                    Set to release in October 2025.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h5" component="h2" gutterBottom>
                    Want to scratch the horror itch now? Check out these games we loved:
                </Typography>

                <Box mb={2}>
                    <Typography variant="body1">
                        Fears to Fathom
                    </Typography>
                    <Link href="https://www.fearstofathom.com/#h.5hlq98zgb25n" target="_blank" rel="noopener noreferrer">
                        Link
                    </Link>
                </Box>

                <Box mb={2}>
                    <Typography variant="body1">
                        Resident Evil 7
                    </Typography>
                    <Link href="https://store.steampowered.com/app/418370/Resident_Evil_7_Biohazard/" target="_blank" rel="noopener noreferrer">
                        Link
                    </Link>
                </Box>

                <Box mb={2}>
                    <Typography variant="body1">
                        Cry of Fear
                    </Typography>
                    <Link href="https://store.steampowered.com/app/223710/Cry_of_Fear/" target="_blank" rel="noopener noreferrer">
                        Link
                    </Link>
                </Box>

                <Box mb={2}>
                    <Typography variant="body1">
                        Spooky's Jump Scare Mansion
                    </Typography>
                    <Link href="https://store.steampowered.com/app/356670/Spookys_Jump_Scare_Mansion/#:~:text=Spooky's%20Jump%20Scare%20Mansion%20on%20Steam&text=Can%20you%20survive%201000%20rooms%20of%20cute%20terror%3F" target="_blank" rel="noopener noreferrer">
                        Link
                    </Link>
                </Box>
            </Grid>
        </>
    );
}

export default TheStartling;