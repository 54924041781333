import React from 'react';
import { Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function NavBar() {
    return (
        <Typography variant="h6" style={{ flexGrow: 1, marginBottom: '16px'}}>
            <Link component={RouterLink} to="/" color="inherit" style={{ margin: '0 1rem' }}>
                Home
            </Link>
            <Link component={RouterLink} to="/thestartling" color="inherit" style={{ margin: '0 1rem' }}>
                The Startling
            </Link>
            <Link component={RouterLink} to="/hookecho" color="inherit" style={{ margin: '0 1rem' }}>
                Hook Echo
            </Link>
            <Link component={RouterLink} to="/aboutus" color="inherit" style={{ margin: '0 1rem' }}>
                About Us
            </Link>
        </Typography>
    );
}

export default NavBar;