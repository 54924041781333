import {Box, Container, Link, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import React from "react";
import TheStartlingDesc from "./TheStartlingDesc";
import HookEchoDesc from "./HookEchoDesc";
import Grid from '@mui/material/Grid';

function Home() {
    return (
        <>
            <Grid item xs={12}>
                <img
                  alt="The Startling"
                  src="images/grewler-games-generated.png"
                  style={{
                    height: "100%",  // Ensure the image spans the width of its container
                    width: "auto",  // Maintain aspect ratio
                    maxHeight: "40vh" // Ensure it doesn't exceed viewport width (optional)
                  }}
                />

                <Grid item xs={12} className={"desc-padding"}>
                    We are an indie development studio currently developing The Startling, our first game set to release in October 2025.
                </Grid>

                <Typography variant="h4" gutterBottom>
                    Upcoming Games
                </Typography>
            </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" style={{ flexGrow: 1, marginBottom: '16px'}}>
                <Link component={RouterLink} to="/thestartling" color="inherit" style={{ margin: '0 1rem' }}>
                    The Startling
                </Link>
            </Typography>
          </Grid>

           <TheStartlingDesc/>

          <Grid item xs={12}>
            <Typography variant="h6" style={{ flexGrow: 1, marginBottom: '16px'}}>
                <Link component={RouterLink} to="/hookecho" color="inherit" style={{ margin: '0 1rem' }}>
                    Hook Echo
                </Link>
            </Typography>
          </Grid>

          <HookEchoDesc/>
    </>)
}

export default Home;